import Link from "next/link";
import { Trans } from "@lingui/macro";
import { ReactElement } from "react";
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { IoLogoDiscord } from "react-icons/io5";
import Image from "next/image";
import BlueTikTokSvg from "@public/blue-tiktok.svg";
import GrayTikTokSvg from "@public/gray-tiktok.svg";

export type LinkProps = {
  subdomain: boolean;
  external?: boolean;
  href: string;
  emoji?: string;
  title: ReactElement;
  withLocale?: boolean;
};

// generate url path based on env
const generatePath = (link: string, prefix: string, locale?: string) => {
  const basePath = process.env.NODE_ENV == "development" ? "" : prefix;
  const localePath = locale ? `/${locale}` : "";
  return basePath + localePath + link;
};

export const generateSubdomainPath = (link: string, locale?: string) =>
  generatePath(link, process.env.SUB_DOMAIN_PREFIX, locale);

export const generateDomainPath = (link: string, locale?: string) =>
  generatePath(link, process.env.MAIN_DOMAIN_PREFIX, locale);

const sharedJSX = (
  link: LinkProps,
  asPath: string,
  jsxProps: string,
  linkColor?: string
) => {
  const isActive = asPath == link.href;

  switch (jsxProps) {
    case "header":
      return (
        <div
          className={`px-4 py-2 font-sen hover:text-text-dark ${
            isActive ? "font-bold text-primary2" : "text-text-medium"
          }`}
        >
          {link.emoji} {link.title}
        </div>
      );
    case "hamburgerMainHeader":
      return (
        <div className="mb-4">
          <span
            className={`font-sen ${
              isActive ? "font-bold text-primary2" : "text-text-medium"
            }`}
          >
            {link.emoji} {link.title}
          </span>
        </div>
      );
    case "hamburgerBtmHeader":
    case "topSidebar":
      return (
        <p className="mr-4 mb-4 font-sen text-xs text-text-medium">
          {link.title}
        </p>
      );
    case "topFooter":
      return (
        <p className={`text-center font-sen md:mt-4 md:text-left ${linkColor}`}>
          {link.title}
        </p>
      );
    case "btmFooter":
      return (
        <p className={`mt-4 font-sen text-sm ${linkColor} md:mt-0`}>
          {link.title}
        </p>
      );
    case "btmSidebar":
      return (
        <p className="font-sen text-xs text-disabled" key={link.href}>
          <Link href={link.href}>
            <a>{link.title}</a>
          </Link>
        </p>
      );
    default:
      return null;
  }
};

const openNewTabJSX = (
  link: LinkProps,
  asPath: string,
  jsxProps: string,
  locale: string,
  linkColor?: string
) => (
  <a
    key={link.href}
    target="_blank"
    href={
      link.subdomain
        ? generateSubdomainPath(link.href, locale)
        : generateDomainPath(link.href, locale)
    }
  >
    {sharedJSX(link, asPath, jsxProps, linkColor)}
  </a>
);

const remainCurrentTabJSX = (
  link: LinkProps,
  asPath: string,
  jsxProps: string,
  linkColor?: string
) => (
  <Link
    href={
      link.subdomain
        ? generateSubdomainPath(link.href)
        : generateDomainPath(link.href)
    }
  >
    <a key={link.href}>{sharedJSX(link, asPath, jsxProps, linkColor)}</a>
  </Link>
);

export const redirectedBrowserTab = (
  link: LinkProps,
  asPath: string,
  showNewTab: boolean,
  locale: string,
  jsxProps: string,
  linkColor?: string
) => {
  const openInNewTab = link.subdomain ? !showNewTab : showNewTab;
  return openInNewTab
    ? openNewTabJSX(link, asPath, jsxProps, locale, linkColor)
    : remainCurrentTabJSX(link, asPath, jsxProps, linkColor);
};

export const sharedLinks: LinkProps[] = [
  // {
  //   subdomain: false,
  //   href: "https://prn.luminews.my/",
  //   external: true,
  //   withLocale: true,
  //   title: <Trans>State Elections</Trans>,
  // },
  // {

  //   subdomain: true,
  //   external: true,
  //   href: "https://quiz.luminews.my/",
  //   title: <Trans>Lumi MPTI Quiz</Trans>,
  // },
  {
    subdomain: false,
    href: "/news",
    title: <Trans>Today's News</Trans>,
  },
  {
    subdomain: false,
    href: "/news-stack",
    title: <Trans>News Stacks</Trans>,
  },
  {
    subdomain: false,
    href: "/news?topic=Good_News",
    title: <Trans>Good News</Trans>,
  },
  {
    subdomain: false,
    href: "/videos",
    title: <Trans>Videos</Trans>,
  },
  {
    subdomain: false,
    href: "/publisher-application",
    title: <Trans>Join as Publisher</Trans>,
  },
  {
    subdomain: false,
    href: "/publishers",
    title: <Trans>Publishers</Trans>,
  },
  {
    subdomain: true,
    href: "/why-use-lumi",
    title: <Trans>Why Use Lumi</Trans>,
  },
  {
    subdomain: true,
    href: "/about-us",
    title: <Trans>About Us</Trans>,
  },
  {
    subdomain: true,
    href: "/careers",
    title: <Trans>Careers</Trans>,
  },
  {
    subdomain: false,
    href: "/contact-us",
    title: <Trans>Contact Us</Trans>,
  },
  {
    subdomain: false,
    external: true,
    href: "https://bit.ly/luminewsmydiscord",
    title: <Trans>Join us on Discord</Trans>,
  },
  {
    subdomain: true,
    href: "/faq",
    title: <Trans>FAQ</Trans>,
  },
  {
    subdomain: false,
    external: true,
    href: "mailto: help@luminews.my",
    title: <Trans> Report a bug</Trans>,
  },
  {
    subdomain: false,
    href: "/t&c",
    title: <Trans>Terms & Conditions</Trans>,
  },
  {
    subdomain: false,
    href: "/privacy-policy",
    title: <Trans>PDPA</Trans>,
  },
];

export const topNavLinks: LinkProps[] = [
  // {
  //   subdomain: false,
  //   href: "https://prn.luminews.my/",
  //   external: true,
  //   withLocale: true,
  //   title: <Trans>State Elections</Trans>,
  // },
  // {
  //   subdomain: true,
  //   external: true,
  //   href: "https://quiz.luminews.my/",
  //   title: <Trans>Lumi MPTI Quiz</Trans>,
  // },
  {
    subdomain: false,
    href: "/news",
    title: <Trans>Today's News</Trans>,
  },
  {
    subdomain: false,
    href: "/videos",
    title: <Trans>Videos</Trans>,
  },
  {
    subdomain: true,
    href: "/why-use-lumi",
    title: <Trans>Why Use Lumi</Trans>,
  },
  {
    subdomain: false,
    href: "/news-stack",
    title: <Trans>News Stacks</Trans>,
  },
];

// To split routes into 2 sections: main links + sub links on hamburger & sidebar
export const selectedIndexForHamburger = sharedLinks.findIndex((link) => {
  return link.href == "/why-use-lumi";
});

export const selectedIndexForSidebar = sharedLinks.findIndex((link) => {
  return link.href == "mailto: help@luminews.my";
});

export const SocialMediaIcons: React.FunctionComponent<{
  tiktokColor: "blue" | "gray";
  iconSize?: number;
}> = ({ tiktokColor, iconSize = 24 }) => {
  const tiktokSvg = {
    blue: BlueTikTokSvg,
    gray: GrayTikTokSvg,
  };
  return (
    <>
      <a
        href="https://www.facebook.com/luminews.my/"
        className="social-media footer-social-media"
      >
        <FaFacebookSquare size={iconSize} />
      </a>
      <a
        href="https://twitter.com/luminewsmy"
        className="social-media footer-social-media ml-4"
      >
        <FaSquareXTwitter size={iconSize} />
      </a>
      <a
        href="https://www.instagram.com/luminews.my/"
        className="social-media footer-social-media ml-4"
      >
        <FaInstagramSquare size={iconSize} />
      </a>
      <a
        href="https://bit.ly/luminewsmydiscord"
        className="social-media footer-social-media ml-4"
      >
        <IoLogoDiscord size={iconSize} />
      </a>
      <a
        href="https://www.tiktok.com/@luminews.my"
        className="social-media footer-social-media ml-4"
      >
        <Image
          src={tiktokSvg[tiktokColor]}
          width={22}
          height={22}
          alt="tiktok"
        />
      </a>
    </>
  );
};
