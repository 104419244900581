import * as React from "react";
import Image from "next/image";
import DownloadNow from "@public/download-button.png";
import DownloadNowBm from "@public/download-button-bm.png";
import DownloadNowZh from "@public/download-button-zh.png";
import useOneLinkUrl from "hooks/useOneLinkUrl";

interface IDownloadNowImageProps {
  size?: "large" | "small";
  locale?: string;
  initialUrl?: string;
  eventName?: string;
}

const SIZE = {
  small: {
    width: 170,
    height: 68,
  },
  large: {
    width: 293,
    height: 120,
  },
};

const LANGUAGE_IMAGE = {
  en: DownloadNow,
  ms: DownloadNowBm,
  zh: DownloadNowZh,
};

const DownloadNowImage: React.FunctionComponent<IDownloadNowImageProps> = ({
  size = "large",
  locale = "en",
  initialUrl = "https://link.luminews.my/ewC9/qrdl",
}) => {
  const url = useOneLinkUrl(initialUrl);

  return (
    <a href={url} id="download-button">
      <Image
        src={LANGUAGE_IMAGE[locale]}
        alt="Read More"
        width={SIZE[size].width}
        height={SIZE[size].height}
        placeholder="blur"
      />
    </a>
  );
};

export default DownloadNowImage;
